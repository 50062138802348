<template>
  <div>
    <director_app_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'รายงานตัวเลือกสถานศึกษา ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
            (period_sedirectors.period_sedirector_times || '-') +
            ' ปี : ' +
            (period_sedirectors.period_sedirector_year || '-')
        "
      >
        <v-card>
          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-center">
                  รอบที่ {{ period_sedirectors.period_sedirector_round }}
                </td>
                <td class="text-center">
                  ลำดับที่ {{ period_sedirectors.period_sedirector_idstart }} -
                  {{ period_sedirectors.period_sedirector_idend }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-card class="pa-1">
          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-center">
                  <h3 class="font-weight-bold" color="info">
                    จำนวนผู้ผ่านการคัดเลือก :
                    {{ sedirector_appCount.sumAll }}
                    คน
                  </h3>
                </td>
                <td class="text-center">
                  <h3 class="font-weight-bold" color="info">
                    สละสิทธิ์ :
                    {{ sedirector_appCount.disclaims }}

                    คน
                  </h3>
                </td>

                <td class="text-center">
                  <v-btn
                    rounded
                    color="info"
                    :href="
                      '#/admin/print_director_app_score_choose/?times=' +
                        period_sedirectors.period_sedirector_times +
                        '&years=' +
                        period_sedirectors.period_sedirector_year
                    "
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon>ส่งออกข้อมูลผู้สมัคร
                  </v-btn>
                </td>
              </tr>

              <tr>
                <td class="text-center">
                  <h3 class="font-weight-bold">
                    เลือกวิทยาลัยเสร็จสิ้น :
                    {{ sedirector_appCount.sedirector_app_collegeapps }}

                    คน
                  </h3>
                </td>
                <td class="text-center">
                  <h3 class="font-weight-bold">
                    ยังไม่ได้ทำรายการ :

                    {{ sedirector_appCount.dontChoose }}

                    คน
                  </h3>
                </td>
                <td class="text-center">
                  <v-btn rounded color="success" @click="noticeSedirector()"
                    ><v-icon>mdi-checkbox-marked-circle-outline</v-icon>ประกาศผล
                  </v-btn>                
                </td>
              </tr>
            </tbody>
          </v-simple-table></v-card
        >

        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
        >
          <template v-slot:item.actions="{ item }">
            <div
              v-if="
                item.sedirector_app_collegeapp_status === 'send' ||
                  item.sedirector_app_collegeapp_status === 'receipt'
              "
            >
              <v-icon color="warning">mdi-cube-send</v-icon>
            </div>
            <div v-else>
              <div
                v-if="item.sedirector_app_id === editedItem.sedirector_app_id"
              >
                <v-icon color="red" class="mr-3" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green" v-if="editedItem.sedirector_app_collegeapp && editedItem.sedirector_app_collegeapp_idpositions" @click="save()">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="red"
                  @click="deleteItem(item.sedirector_app_idref)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </div>
          </template>

          <template v-slot:item.sedirector_app_collegeapp="{ item }">
            <v-autocomplete
              v-model="editedItem.sedirector_app_collegeapp"
              label="วิทยาลัย"
              :items="director_college_manpowers"
              item-text="college_namemain"
              item-value="dcm_college_code"
              v-if="item.sedirector_app_id === editedItem.sedirector_app_id"
              :rules="[v => !!v || '']"
              @change="director_college_manpowerQueryID()"
            >
            <template v-slot:item="{item}">
            <div>
              <div class="font-weight-bold">
                {{ item.college_namemain }}
              </div>
              <div>
                {{ item.province_name }}
              </div>
            </div>
          </template>
        </v-autocomplete>
            <div v-else>
              <v-chip v-if="item.sedirector_app_collegeapp" color="success">
                {{ item.collegenameChoose }}</v-chip
              >
              <v-chip color="grey" dark v-else>
                {{ item.collegenameChoose || "-" }}
              </v-chip>
            </div>
          </template>

          <template
            v-slot:item.sedirector_app_collegeapp_idpositions="{ item }"
          >
            <v-autocomplete
              v-model="editedItem.sedirector_app_collegeapp_idpositions"
              label="เลขที่ตำแหน่ง"
              :items="director_college_manpower_idpositions"
              item-text="dcm_idpositons"
              item-value="dcm_idpositons"
              v-if="item.sedirector_app_id === editedItem.sedirector_app_id"
              :rules="[v => !!v || '']"
            >
              <template v-slot:item="{ item }">
                <div>
                  <div>
                    {{ item.dcm_idpositons }}
                  </div>
                  <div>: {{ item.whoBooking }}</div>
                </div>
              </template>
            </v-autocomplete>
            <div v-else>
              <v-chip
                v-if="item.sedirector_app_collegeapp_idpositions"
                color="success"
              >
                {{ item.sedirector_app_collegeapp_idpositions }}</v-chip
              >
              <v-chip color="grey" dark v-else>
                {{ item.sedirector_app_collegeapp_idpositions || "-" }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_status_report="{ item }">
            <div
              v-if="
                item.sedirector_app_collegeapp_status === 'send' ||
                  item.sedirector_app_collegeapp_status === 'receipt'
              "
            >
              <v-icon color="warning">mdi-cube-send</v-icon>
            </div>
            <div v-else>
              <v-btn
                @click="updatesedirector_apps(item.sedirector_app_idref)"
                rounded
                fab
                small
                color="warning"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model sedirector_app_score_c1_filedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="sedirector_app_score_c1_filedialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import director_app_bar from "../../../components/admin/director/director_app_bar";

export default {
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      sedirector_app_idref: [],
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },

      sedirector_apps: [],

      search: "",
      pagination: {},
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "จังหวัด", align: "center", value: "province_name" },
        {
          text: "ลำดับที่",
          align: "center",
          value: "sedirector_app_idnoaccount"
        },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        /*       { text: "สถานศึกษาปัจจุบัน", align: "left", value: "college_name" }, */
        { text: "เลือก", align: "center", value: "actions" },

        {
          text: "เลือกวิทยาลัย",
          align: "center",
          value: "sedirector_app_collegeapp"
        },
        {
          text: "เลขที่ตำแหน่ง",
          align: "center",
          value: "sedirector_app_collegeapp_idpositions"
        },

        {
          text: "สถานะ",
          align: "center",
          value: "sedirector_app_status_report"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      sedirector_apps_id_ref: [],

      period_sedirectors: [],
      sedirector_appCount: {},
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      pdffiles: "",
      sedirector_app_score_c1_filedialog: false,
      editedIndex: -1,
      editedItem: {
        sedirector_app_id: 0,
        statusAdd: ""
      },
      editedItemUpdate: {},
      defaultItem: {
        sedirector_app_id: 0,
        statusAdd: "add"
      },

      deletedItemmat: {},
      deletedItemmcm: {},
      sedirector_appsidrefs: [],
      director_college_manpowers: [],
      director_college_manpower_idpositions: [],
      update_director_college_manpowers: {},
      update_sedirector_app_notice: {}
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    await this.period_sedirectorQuery();
    
  },
  methods: {
    async editItem(item) {
      this.editedIndex = this.sedirector_apps.indexOf(item);
      this.editedItem = Object.assign({}, item);

      let result = await this.$http.post("sedirector_app_dir.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: item.sedirector_app_idref
      });
      this.sedirector_appsidrefs = result.data;
      await this.director_college_manpowerQuery();
    },

    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      this.editedItem.ApiKey = this.ApiKey;
      Object.assign(this.sedirector_apps[this.editedIndex], this.editedItem);

      let result = await this.$http.post(
        "sedirector_app.update.php",
        this.editedItem
      );

      this.update_director_college_manpowers.ApiKey = this.ApiKey;
      this.update_director_college_manpowers.dcm_times = this.period_sedirectors.period_sedirector_times;
      this.update_director_college_manpowers.dcm_years = this.period_sedirectors.period_sedirector_year;
      this.update_director_college_manpowers.dcm_idpositons = this.editedItem.sedirector_app_collegeapp_idpositions;
      this.update_director_college_manpowers.dcm_id_card_booking = this.editedItem.sedirector_app_idcard;

      let result_man = await this.$http.post(
        "director_college_manpower.update.php",
        this.update_director_college_manpowers
      );

      if (result.data.status == true && result_man.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ทำรายการซ้ำ",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.sedirector_appQueryAll();
      this.close();
    },
    async deleteItem(sedirector_app_idref) {
      let result = await this.$http.post("sedirector_app_dir.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_appsidrefs = result.data;

      let fristnames = this.sedirector_appsidrefs.fristnames;
      Swal.fire({
        title: "คุณต้องการยกเลิกคะแนนรายการนี้?",
        text: fristnames,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรายการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.update_director_college_manpowers.ApiKey = this.ApiKey;
          this.update_director_college_manpowers.dcm_times = this.period_sedirectors.period_sedirector_times;
          this.update_director_college_manpowers.dcm_years = this.period_sedirectors.period_sedirector_year;
          this.update_director_college_manpowers.dcm_idpositons = this.sedirector_appsidrefs.sedirector_app_collegeapp_idpositions;
          this.update_director_college_manpowers.dcm_id_card_booking = "";

          let result_man = await this.$http.post(
            "director_college_manpowerID.update.php",
            this.update_director_college_manpowers
          );

          this.sedirector_appsidrefs.ApiKey = this.ApiKey;
          this.sedirector_appsidrefs.sedirector_app_collegeapp = "";
          this.sedirector_appsidrefs.sedirector_app_collegeapp_idpositions = "";

          let resultupdatemat = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_appsidrefs
          );
        }
        this.sedirector_appQueryAll();
      });
    },

    async director_college_manpowerQuery() {
      let result = await this.$http.post("director_college_manpower.php", {
        ApiKey: this.ApiKey,
        dcm_types: "ผอ.",
        dcm_times: this.period_sedirectors.period_sedirector_times,
        dcm_years: this.period_sedirectors.period_sedirector_year,
        dcm_id_card_booking: "Ok"
      });
      this.director_college_manpowers = result.data;
    },

    async director_college_manpowerQueryID() {
      let result = await this.$http.post("director_college_manpower.php", {
        ApiKey: this.ApiKey,
        dcm_types: "ผอ.",
        dcm_times: this.period_sedirectors.period_sedirector_times,
        dcm_years: this.period_sedirectors.period_sedirector_year,
        dcm_college_code: this.editedItem.sedirector_app_collegeapp
      });
      this.director_college_manpower_idpositions = result.data;
    },

    async updatesedirector_apps(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app_dir.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let text = this.sedirector_apps_id_ref.fristnames;
      Swal.fire({
        title: "รายการนี้สละสิทธิ์ ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ขอยกเลิก",
        cancelButtonText: "ปิด"
      }).then(async result => {
        if (result.isConfirmed) {
          let result;
          this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
          this.sedirector_apps_id_ref.sedirector_app_status_report = "disclaim";
          result = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps_id_ref
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async noticeSedirector() {
      Swal.fire({
        title: "ประกาศผลการเลือกสถานศึกษา ?",
        text: "แจ้งวิทยาลัยปลายทางทราบ และรับรายงานตัว",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ประกาศ",
        cancelButtonText: "ยกเลิก",
        denyButtonText: `ยกเลิกประกาศ`
      }).then(async result => {
        if (result.isConfirmed) {
          let result;
          this.update_sedirector_app_notice.ApiKey = this.ApiKey;
          this.update_sedirector_app_notice.sedirector_app_year = this.period_sedirectors.period_sedirector_year;
          this.update_sedirector_app_notice.sedirector_app_time = this.period_sedirectors.period_sedirector_times;
          this.update_sedirector_app_notice.sedirector_app_collegeapp_status ="send";
          this.update_sedirector_app_notice.period_sedirector_idstart =this.period_sedirectors.period_sedirector_idstart;
          this.update_sedirector_app_notice.period_sedirector_idend =this.period_sedirectors.period_sedirector_idend;        

          result = await this.$http.post(
            "sedirector_appApprove.update.php",
            this.update_sedirector_app_notice
          );

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else if (result.isDenied) {
          let result;
          this.update_sedirector_app_notice.ApiKey = this.ApiKey;
          this.update_sedirector_app_notice.sedirector_app_year = this.period_sedirectors.period_sedirector_year;
          this.update_sedirector_app_notice.sedirector_app_time = this.period_sedirectors.period_sedirector_times;
          this.update_sedirector_app_notice.sedirector_app_collegeapp_status =
            "";
          result = await this.$http.post(
            "sedirector_appApprove.update.php",
            this.update_sedirector_app_notice
          );

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },


    async period_sedirectorQuery() {
      let result_period_sedirector;
      result_period_sedirector = await this.$http.post(
        "period_sedirector.php",
        {
          ApiKey: this.ApiKey,
          period_sedirector_process: "1",
          period_sedirector_id: "207"
        }
      );
      this.period_sedirectors = result_period_sedirector.data;
      if(this.period_sedirectors.period_sedirector_process==='1'){
        await this.sedirector_appQueryAll();
      }
    },

    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app_dir.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype: 3,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year,
          sedirector_app_ipastatus: "guarantee",
          period_sedirector_idstart: this.period_sedirectors
            .period_sedirector_idstart,
          period_sedirector_idend: this.period_sedirectors
            .period_sedirector_idend
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;

      let data = this.sedirector_apps;
      let sum = 0;
      let sedirector_app_collegeapps = 0;
      let disclaims = 0;
      let dontChoose = 0;
      data.forEach(value => {
        if (value.sedirector_app_ipastatus == "guarantee") {
          sum += 1;
        }
        let collegeapp = String(value.sedirector_app_collegeapp);
        if (collegeapp.length > 5) {
          sedirector_app_collegeapps += 1;
        } else {
          dontChoose += 1;
        }

        if (value.sedirector_app_status_report == "disclaim") {
          disclaims += 1;
        }
      });
      this.sedirector_appCount.sumAll = sum;
      this.sedirector_appCount.sedirector_app_collegeapps = sedirector_app_collegeapps;
      this.sedirector_appCount.disclaims = disclaims;
      this.sedirector_appCount.dontChoose = dontChoose;
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_sedirector_years() {
      let yyyy = this.period_sedirectors.period_sedirector_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { director_app_bar }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
