var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('director_app_bar'),_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{attrs:{"icon":"mdi-clipboard-text","title":'รายงานตัวเลือกสถานศึกษา ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
          (_vm.period_sedirectors.period_sedirector_times || '-') +
          ' ปี : ' +
          (_vm.period_sedirectors.period_sedirector_year || '-')}},[_c('v-card',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" รอบที่ "+_vm._s(_vm.period_sedirectors.period_sedirector_round)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" ลำดับที่ "+_vm._s(_vm.period_sedirectors.period_sedirector_idstart)+" - "+_vm._s(_vm.period_sedirectors.period_sedirector_idend)+" ")])])])])],1),_c('v-card',{staticClass:"pa-1"},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('h3',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" จำนวนผู้ผ่านการคัดเลือก : "+_vm._s(_vm.sedirector_appCount.sumAll)+" คน ")])]),_c('td',{staticClass:"text-center"},[_c('h3',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" สละสิทธิ์ : "+_vm._s(_vm.sedirector_appCount.disclaims)+" คน ")])]),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"info","href":'#/admin/print_director_app_score_choose/?times=' +
                      _vm.period_sedirectors.period_sedirector_times +
                      '&years=' +
                      _vm.period_sedirectors.period_sedirector_year,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v("ส่งออกข้อมูลผู้สมัคร ")],1)],1)]),_c('tr',[_c('td',{staticClass:"text-center"},[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" เลือกวิทยาลัยเสร็จสิ้น : "+_vm._s(_vm.sedirector_appCount.sedirector_app_collegeapps)+" คน ")])]),_c('td',{staticClass:"text-center"},[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" ยังไม่ได้ทำรายการ : "+_vm._s(_vm.sedirector_appCount.dontChoose)+" คน ")])]),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"rounded":"","color":"success"},on:{"click":function($event){return _vm.noticeSedirector()}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v("ประกาศผล ")],1)],1)])])])],1),_c('v-select',{attrs:{"items":_vm.headers,"label":"เลือกคอลัมน์ที่ต้องการแสดง","multiple":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [(index < 6)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 6)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 6)+" คอลัมน์)")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.showHeaders,"items":_vm.sedirector_apps,"search":_vm.search,"item-key":"sedirector_app_idref"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [(
              item.sedirector_app_collegeapp_status === 'send' ||
                item.sedirector_app_collegeapp_status === 'receipt'
            )?_c('div',[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-cube-send")])],1):_c('div',[(item.sedirector_app_id === _vm.editedItem.sedirector_app_id)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},[_vm._v(" mdi-window-close ")]),(_vm.editedItem.sedirector_app_collegeapp && _vm.editedItem.sedirector_app_collegeapp_idpositions)?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" mdi-content-save ")]):_vm._e()],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item.sedirector_app_idref)}}},[_vm._v(" mdi-delete ")])],1)])]}},{key:"item.sedirector_app_collegeapp",fn:function(ref){
            var item = ref.item;
return [(item.sedirector_app_id === _vm.editedItem.sedirector_app_id)?_c('v-autocomplete',{attrs:{"label":"วิทยาลัย","items":_vm.director_college_manpowers,"item-text":"college_namemain","item-value":"dcm_college_code","rules":[function (v) { return !!v || ''; }]},on:{"change":function($event){return _vm.director_college_manpowerQueryID()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.college_namemain)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.province_name)+" ")])])]}}],null,true),model:{value:(_vm.editedItem.sedirector_app_collegeapp),callback:function ($$v) {_vm.$set(_vm.editedItem, "sedirector_app_collegeapp", $$v)},expression:"editedItem.sedirector_app_collegeapp"}}):_c('div',[(item.sedirector_app_collegeapp)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.collegenameChoose))]):_c('v-chip',{attrs:{"color":"grey","dark":""}},[_vm._v(" "+_vm._s(item.collegenameChoose || "-")+" ")])],1)]}},{key:"item.sedirector_app_collegeapp_idpositions",fn:function(ref){
            var item = ref.item;
return [(item.sedirector_app_id === _vm.editedItem.sedirector_app_id)?_c('v-autocomplete',{attrs:{"label":"เลขที่ตำแหน่ง","items":_vm.director_college_manpower_idpositions,"item-text":"dcm_idpositons","item-value":"dcm_idpositons","rules":[function (v) { return !!v || ''; }]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('div',[_vm._v(" "+_vm._s(item.dcm_idpositons)+" ")]),_c('div',[_vm._v(": "+_vm._s(item.whoBooking))])])]}}],null,true),model:{value:(_vm.editedItem.sedirector_app_collegeapp_idpositions),callback:function ($$v) {_vm.$set(_vm.editedItem, "sedirector_app_collegeapp_idpositions", $$v)},expression:"editedItem.sedirector_app_collegeapp_idpositions"}}):_c('div',[(item.sedirector_app_collegeapp_idpositions)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.sedirector_app_collegeapp_idpositions))]):_c('v-chip',{attrs:{"color":"grey","dark":""}},[_vm._v(" "+_vm._s(item.sedirector_app_collegeapp_idpositions || "-")+" ")])],1)]}},{key:"item.sedirector_app_status_report",fn:function(ref){
            var item = ref.item;
return [(
              item.sedirector_app_collegeapp_status === 'send' ||
                item.sedirector_app_collegeapp_status === 'receipt'
            )?_c('div',[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-cube-send")])],1):_c('div',[_c('v-btn',{attrs:{"rounded":"","fab":"","small":"","color":"warning"},on:{"click":function($event){return _vm.updatesedirector_apps(item.sedirector_app_idref)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.sedirector_app_score_c1_filedialog),callback:function ($$v) {_vm.sedirector_app_score_c1_filedialog=$$v},expression:"sedirector_app_score_c1_filedialog"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('embed',{attrs:{"src":'/HrvecRegisDir/' + _vm.pdffiles,"width":"100%","height":"700px"}})])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.snackbar.icon))]),_c('v-card-text',[_vm._v(_vm._s(_vm.snackbar.text))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }